/*
 * @Date: 2023-03-14 14:02:09
 * @LastEditors: simLarry
 * @LastEditTime: 2023-05-04 16:02:09
 * @FilePath: \J1-M1-QRP-CODE\pages\m1-001.js
 */
/*
 * @Date: 2023-03-14 14:02:09
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-17 15:03:31
 * @FilePath: \F1-M1-QRP-CODE\pages\m1-002.js
 */
import React from "react";
import Register from "../components/register";
import Download from "../components/download" // IOS Android 二维码下载;
import Banner from "../components/hayhar/banner";
import DownHeader from '@/AppHead'; // Header APP 下载 显示
import Header from '@/hayhar/header'; // 头部 组件
import Footer from '@/hayhar/footer'; // 底部内容
import Socialbtn from '@/hayhar/socialbtn'; // 社交按钮
import Modal from '@/modal'; //
import { checkAffQueryString ,Cookie, _handleTheMainSiteUrlOfBackendResponse } from '../actions/util';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { Apiversion } from '../config/Fatch.config';
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
import { ApiPort } from '$ACTIONS/API';
import Router from 'next/router';


import setupTraces from "../config/trace";
import { bannerConfigs } from "../config/default/banner";
setupTraces('m1-001');



export default class hayHar extends React.Component {

    constructor() {
        super();
        this.state = {
            bannerList: [],
            pagename: '',

            mainSiteUrl : '',
            liveChatUrl : '',
            affiliateUrlLM : '',

            isModal:false,
            error_text : '',

            qrpic:'', // qrcode
            nativeAppUrl:'',

            showDownHeader:false, // 是否显示头部下载按钮
            affiliateValue: '', // To store the affiliate value
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getPageWidth = this.getPageWidth.bind(this);
        this.hideHeader = this.hideHeader.bind(this);
        this.addAffiliateParamToUrl = this.addAffiliateParamToUrl.bind(this)
    }

    componentDidMount() {
        this.init();
    }

    init() {
        // 获取URL 参数
        checkAffQueryString(global.location.search);
        // 获取当前page
        this.getPageName();
        // 获取banner
        this.getBanner();
        //
        this.QrpLoad();

        this.AffiliateLink();

         // Fetch the affiliate value from the cookie after the component mounts
         const affiliateValue = Cookie.GetCookieKeyValue('CO_affiliate');
         this.setState({ affiliateValue });
    }

    /**
     * 获取当前路径
     */
    getPageName() {
        console.log(Router.asPath);
        this.setState({
            bannerList : bannerConfigs['m1-001']
        })
    }

    getBanner() {
        let bannerList;
        fetchRequest(ApiPort.Banner1, 'GET').then(res => {
            try {
                if (res) {
                    if (res.length > 0) {
                        this.state.bannerList = res.map((val, index) => {
                            return { cmsImageUrlWeb: CMSURL + val.cmsImageUrlWeb, cmsImageUrlMobile: CMSURL + val.cmsImageUrlMobile , cmsImageUrlMobileAlt : val.cmsImageUrlMobileAlt , cmsImageUrlWebAlt : val.cmsImageUrlWebAlt}
                        });
                    }

                }
            } catch (error) {

            }
        })
    }



    async QrpLoad() {

        let data = await fetchRequest(ApiPort.QrpLoad, 'GET', '', Apiversion);
        console.log(data);
        if(data.isSuccess){
            this.setState({
                mainSiteUrl : data.mainSiteUrl,
                liveChatUrl :  data.liveChatUrl,
                nativeAppDownloadUrl : data.nativeAppDownloadUrl,
                affiliateUrlLM : data.affiliateUrlLM
            })
        }
    }


    /**
     *
     * @param {*} nativeAppUrl
     */

    getQrcodeUrl(nativeAppUrl){
        let aff = Cookie.GetCookieKeyValue('CO_affiliate') ?  '?aff=' + Cookie.GetCookieKeyValue('CO_affiliate'): '';
        let params = {
            text : nativeAppUrl
        }
        fetchRequest(ApiPort.Qrcode ,'POST' , params).then(res =>{
            console.log('res===',res);
            this.setState({
                qrpic:res.content
            })
        })
    }


    /**
     * 获取 APP 下载页面
     */
    async AffiliateLink(){
        const affCode = Cookie.GetCookieKeyValue('CO_affiliate') ?  '&affiliateCode=' + Cookie.GetCookieKeyValue('CO_affiliate') : '';
        let Apiversion = '?api-version=1.0&brand=JBO&Platform=Android' + affCode;
        let data = await fetchRequest(ApiPort.AffiliateLink , 'GET' , '' ,Apiversion);
        if(data.isSuccess){
            this.setState({
                nativeAppUrl:data.nativeAppUrl
            },()=>{
                // this.getQrcodeUrl(data.nativeAppUrl);
            })

        }
    }


    // 关闭弹窗
    closeModal(){
        this.setState({
            isModal : false
        })
    }

    /**
     * 显示弹窗
     * @param {string} text
     */
    openModal(text){
        this.setState({
            error_text : text,
            isModal : !this.state.isModal
        })
    }

    /**
     * 获取当前页面宽度
     * @param {number} event
     */
    getPageWidth(event){
        console.log('event',event);
        let showFlag = false;
        if(event < 500){
            showFlag = true;
        }
        this.setState({
            showDownHeader : showFlag
        });
    }


    hideHeader(event){
       this.setState({
        showDownHeader : event
       })
    }

    /**
     * 回到主站
     */
    goHome(){
        global.globalGtag &&global.globalGtag('Style2_QRP','homepage' , 'homepage' ,'event');
        _handleTheMainSiteUrlOfBackendResponse(this.state.mainSiteUrl);
    }


     // Function to add the 'aff' parameter to the URL if the 'CO_affiliate' cookie is present
		 addAffiliateParamToUrl(url) {
			const { affiliateValue } = this.state;
			if (affiliateValue) {
				try {
					let urlObj = new URL(url, window.location.origin); // Use base URL for relative paths
					urlObj.searchParams.set('aff', affiliateValue);
					return urlObj.toString();
				} catch (error) {
					console.error("Invalid URL:", url);
				}
			}
			return url;
		}

    render(){
        let { mainSiteUrl, qrpic , nativeAppUrl , affiliateUrlLM} = this.state;
        return (

            <div className={`hayhar-page m1-001`}>
                {/* 头部登录按钮  */}
                <Header openModal={this.openModal} mainSiteUrl={mainSiteUrl} addAffiliateParamToUrl={this.addAffiliateParamToUrl}></Header>
                {/* banner */}
                <Banner  bannerList={this.state.bannerList} getPageWidth={this.getPageWidth} ></Banner>

                <div className="hayhar-middle">
                    {/* 注册 */}
                    <Register openModal={this.openModal} addAffiliateParamToUrl={this.addAffiliateParamToUrl}></Register>
                    {/* 下载 */}
                    <Download nativeAppUrl={nativeAppUrl} qrpic={qrpic} affiliateUrlLM={affiliateUrlLM}></Download>
                </div>
                {/* 页脚 */}
                <Footer mainSiteUrl={mainSiteUrl} affiliateUrlLM={affiliateUrlLM} addAffiliateParamToUrl={this.addAffiliateParamToUrl}></Footer>
                {/* 联系方式 */}
                {/*<Socialbtn liveChatUrl={liveChatUrl}></Socialbtn>*/}
                {/* 弹窗 */}
                <Modal error_text={this.state.error_text} closeModal={this.closeModal} isModal={this.state.isModal}></Modal>
            </div>
        )
    }

}
